export const UI_MENU_BASE_FRAGMENT = `
  fragment uiMenuBaseFragment on UiMenu {
        uid
        schemaCode
        name
        code
        production
        active
        version
        items(orderBy: displaySequence_asc) {
            uid
            type
            payload
            displaySequence
            parentMenuItem {
                uid
            }
            subMenuItem(orderBy: displaySequence_asc) {
                uid
                type
                displaySequence
                payload
            }
        }
  }
`;
